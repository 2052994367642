<template>
  <div>
    <b-modal
      id="product-already-in-cart-modal"
      hide-footer
      hide-header
      size="lg"
    >
      <div id="product-already-in-cart-modal-content-div">
        <div class="mt-4 mb-4">
          <h4>{{ title }}</h4>

          <div v-html="sanitizedMessage" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'StagelineAlertModal',
  props: {
    title: {
      type: String,
      default: 'Note:',
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    sanitizedMessage() {
      return DOMPurify.sanitize(this.message)
    },
  },
}
</script>


<style>
</style>
